import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { FlagIcon } from "react-flag-kit";

const Footer = ({ isAbout = false }) => {
  const t = useTranslations();

  return (
    <>
      <hr className="border-neutral-900 border-opacity-25" />
      <footer className="dark:bg-black-dark relative z-10 bg-white pt-16 md:pt-20 lg:pt-0">
        <div className="container mx-auto py-12">
          {/* Top Section with Logo and Description */}
          <div className="flex flex-col flex-wrap justify-between gap-8 pb-12 md:flex-row">
            <div className="w-full md:w-1/3">
              <Image
                src="/images/logo/logo5.svg"
                alt="logo"
                width={200}
                height={100}
                className="dark:hidden"
              />
              <p className="mt-4 text-black-600 pb-5">
                {t('footer.desc')}
              </p>

            {/*  here*/}
              <div className="flex items-center gap-3">
                <div className="flex h-full flex-row flex-wrap gap-x-3 gap-y-3">
                  <div>
                    <a
                      className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-ga-slug="download on app store"
                      href="https://apps.apple.com/az/app/esimafly/id6618155522?platform=iphone"
                    >
                      <div>
                        <Image
                          alt="apple store"
                          loading="lazy"
                          width="143"
                          height="48"
                          decoding="async"
                          data-nimg="1"
                          src="https://sb.nordcdn.com/m/3bd4c58600abc36b/original/apple-store.svg"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <a
                    className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-ga-slug="download on android"
                    href="https://play.google.com/store/apps/details?id=com.esimafly.app"
                  >
                    <div>
                      <picture>
                        <Image
                          alt="google play store"
                          loading="lazy"
                          width="143"
                          height="48"
                          decoding="async"
                          data-nimg="1"
                          src="https://sb.nordcdn.com/m/61c12f9617ed35b4/original/google-play-store.svg"
                        />
                      </picture>
                    </div>
                  </a>
                </div>
              </div>
              {/*here*/}
            </div>

            {/* Popular Countries */}
            <div className="w-full md:w-1/6">
              <h4 className="font-semibold text-black-700">{t('footer.popular_countries')}</h4>
              <ul className="mt-4 text-black-600 space-y-1.5">
                <li className="flex items-center">
                  <Link href="/US-packages" className="flex items-center">
                    <FlagIcon code="US" size={24} />
                    <span className="ml-2">{t('countries_list.US')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/TH-packages" className="flex items-center">
                    <FlagIcon code="TH" size={24} />
                    <span className="ml-2">{t('countries_list.TH')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/GB-packages" className="flex items-center">
                    <FlagIcon code="GB" size={24} />
                    <span className="ml-2">{t('countries_list.GB')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/ES-packages" className="flex items-center">
                    <FlagIcon code="ES" size={24} />
                    <span className="ml-2">{t('countries_list.ES')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/TR-packages" className="flex items-center">
                    <FlagIcon code="TR" size={24} />
                    <span className="ml-2">{t('countries_list.TR')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/IT-packages" className="flex items-center">
                    <FlagIcon code="IT" size={24} />
                    <span className="ml-2">{t('countries_list.IT')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/CN-packages" className="flex items-center">
                    <FlagIcon code="CN" size={24} />
                    <span className="ml-2">{t('countries_list.CN')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/FR-packages" className="flex items-center">
                    <FlagIcon code="FR" size={24} />
                    <span className="ml-2">{t('countries_list.FR')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/JP-packages" className="flex items-center">
                    <FlagIcon code="JP" size={24} />
                    <span className="ml-2">{t('countries_list.JP')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/DE-packages" className="flex items-center">
                    <FlagIcon code="DE" size={24} />
                    <span className="ml-2">{t('countries_list.DE')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link href="/ID-packages" className="flex items-center">
                    <FlagIcon code="ID" size={24} />
                    <span className="ml-2">{t('countries_list.ID')}</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* About Us */}
            <div className="w-full md:w-1/6">
              <h4 className="font-semibold text-black-700">{t('about_page.about_us_title')}</h4>
              <ul className="mt-4 text-black-600">
                <li><Link href="/about">{t('about_us')}</Link></li>
                <li><Link href="/blog">{t('about_page.blog')}</Link></li>

              </ul>
            </div>

            {/* More Info */}
            <div className="w-full md:w-1/6">
              <h4 className="font-semibold text-black-700">{t('footer.more_info')}</h4>
              <ul className="mt-4 text-black-600">
                <li><Link href="/contact">{t('help_center')}</Link></li>
                {/*<li><Link href="/">Blog</Link></li>*/}
                {/*<li><Link href="/">Referral</Link></li>*/}
                {/*<li><Link href="/">Manage Cookies</Link></li>*/}
              </ul>
            </div>

          </div>

          <hr className="border-neutral-900 border-opacity-25" />

          {/* Bottom Section */}
          <div className="mt-8">
            <div className="flex flex-col-reverse flex-wrap-reverse items-center justify-between gap-8 md:flex-row">
              <p className="body-xs-medium flex flex-wrap items-start gap-6 font-normal sm:items-center">
                <span className="text-tertiary font-normal">
                  2024 Esimafly. {t("all_rights_reserved")}
                </span>
                <span className="flex flex-wrap items-center gap-6">
                  <Link
                    className="focus-visible:shadow-focus text-tertiary inline-block align-bottom underline transition-colors ease-out hover:text-neutral-700 focus-visible:outline-none"
                    data-ga-slug="Privacy Policy"
                    href="/privacy-policy"
                  >
                    {t("privacy_policy")}
                  </Link>
                  <Link
                    className="focus-visible:shadow-focus text-tertiary inline-block align-bottom underline transition-colors ease-out hover:text-neutral-700 focus-visible:outline-none"
                    data-ga-slug="Terms of Service"
                    href="/terms-of-service"
                  >
                    {t("terms_of_service.termsOfService")}
                  </Link>
                </span>
              </p>

              {/*{!isAbout && (*/}
              {/*  <div className="flex items-center gap-3">*/}
              {/*    <div className="flex h-full flex-row flex-wrap gap-x-3 gap-y-3">*/}
              {/*      <div>*/}
              {/*        <a*/}
              {/*          className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"*/}
              {/*          target="_blank"*/}
              {/*          rel="noopener noreferrer"*/}
              {/*          data-ga-slug="download on app store"*/}
              {/*          href="https://apps.apple.com/az/app/esimafly/id6618155522"*/}
              {/*        >*/}
              {/*          <div>*/}
              {/*            <Image*/}
              {/*              alt="apple store"*/}
              {/*              loading="lazy"*/}
              {/*              width="143"*/}
              {/*              height="48"*/}
              {/*              decoding="async"*/}
              {/*              data-nimg="1"*/}
              {/*              src="https://sb.nordcdn.com/m/3bd4c58600abc36b/original/apple-store.svg"*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </a>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*        <a*/}
              {/*            className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"*/}
              {/*            target="_blank"*/}
              {/*            rel="noopener noreferrer"*/}
              {/*            data-ga-slug="download on android"*/}
              {/*            href="https://play.google.com/store/apps/details?id=com.esimafly.app"*/}
              {/*        >*/}
              {/*            <div>*/}
              {/*                <picture>*/}
              {/*                    <Image*/}
              {/*                        alt="google play store"*/}
              {/*                        loading="lazy"*/}
              {/*                        width="143"*/}
              {/*                        height="48"*/}
              {/*                        decoding="async"*/}
              {/*                        data-nimg="1"*/}
              {/*                        src="https://sb.nordcdn.com/m/61c12f9617ed35b4/original/google-play-store.svg"*/}
              {/*                    />*/}
              {/*                </picture>*/}
              {/*            </div>*/}
              {/*        </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*)}*/}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
